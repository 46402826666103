<template>
  <div class="ui-rounded-full ui-overflow-hidden ui-w-full ui-h-full">
    <div
      v-if="loading"
      class="c-bg-gray-100 ui-h-full ui-w-full"
    >
      <c-skeleton-block />
    </div>
    <img
      v-else-if="url"
      :src="url"
      alt="Avatar"
      class="ui-h-full ui-w-full"
    >
    <div
      v-else
      class="c-d-flex ui-h-full ui-w-full ui-items-center ui-justify-center ui-rounded-full c-bg-primary-400 ui-font-semibold c-text-white"
      :style="{ fontSize }"
    >
      {{ initials }}
    </div>
  </div>
</template>

<script>
import { CSkeletonBlock } from '@estrategiahq/coruja-web-ui';

export default {
  components: { CSkeletonBlock },
  props: {
    url: { type: String, default: '' },
    name: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    fontSize: { type: String, default: '12px' },
  },
  computed: {
    initials() {
      const fullName = this.name || '';
      const names = fullName.match(/[a-zÀ-ú]+/gi) || []; // https://regex101.com/r/3FG2yX/2
      const initials = names.map(x => x[0]);

      const first = initials.shift() || '';
      const last = initials.pop() || '';

      return (first + last).toUpperCase() || 'SN';
    },
  },
};
</script>
