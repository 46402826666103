<template>
  <div v-if="loadingUser || loadingSessions">
    Carregando...
  </div>
  <div
    v-else
    class="c-row ui-mb-8"
  >
    <div class="ui-m-4 ui-mt-8 c-col">
      <!-- Título -->
      <div class="c-row ui-mb-4">
        <div class="sm:c-col-1 md:c-col-6 lg:c-col-6 c-d-flex">
          <c-button
            outline
            size="sm"
            color="gray"
            data-cy="voltarButton"
            class="ui-mt-2 ui-mr-4 c-d-flex c-center back-button border-gray"
            @click="backToUsersList()"
          >
            <c-icon
              name="arrow-left"
              size="small"
              color="inherit"
              outline
            />
          </c-button>
          <span class="c-text-h2 c-text-gray-400 c-center">
            Perfil
          </span>
        </div>
        <div class="ui-justify-end c-d-flex sm:c-col-4 md:c-col-6 lg:c-col-6">
          <c-button
            outline
            color="gray"
            class="ui-mr-3"
            data-cy="pagamentosButton"
            style="min-width:254px"
            title="Pagamentos"
            @click="$router.push({ name: 'user-payment', params: { id: userID } });"
          >
            <c-icon
              outline
              class="ui-mr-2"
              name="money-dollar-circle"
              color="inherit"
            />
            Pagamento de professores
          </c-button>
          <c-button
            outline
            data-cy="editarButton"
            title="Editar"
            class="c-center ui-mr-3"
            style="min-width:172px"
            @click="$router.push({ name: 'user-edit', params: { id: userID } });"
          >
            <icon
              class="ui-mr-2"
              name="edit"
              outline
            />
            Editar usuário
          </c-button>
          <c-button
            outline
            data-cy="editarButton"
            title="Editar"
            class="c-center"
            style="min-width:172px"
            v-if="isLoginAsOtherUserEnable"
            @click="loginAsOtherUser()"
          >
            <icon
              class="ui-mr-2"
              name="send"
              outline
            />
            Logar como usuário
          </c-button>
        </div>
      </div>

      <div class="ui-mb-8">
        <div class="c-col">
          <card class="c-row ui-h-full">
            <div class="sm:c-col-1 md:c-col-1 lg:c-col-1 ui-mb-2">
              <div
                class="ui-rounded-full ui-relative ui-cursor-pointer"
                id="avatar-container"
              >
                <p-avatar
                  :name="user.full_name"
                  :url="user.avatar_url"
                  font-size="20px"
                />
              </div>
            </div>
            <div class="sm:c-col-11 md:c-col-11 lg:c-col-11 c-d-flex ui-mt-5">
              <span class="c-text-h2 c-text-gray-400">
                {{ user.full_name }}
              </span>
            </div>
          </card>
        </div>
      </div>

      <div class="c-row ui-mb-2">
        <div class="c-col">
          <span class="c-text-h3 c-text-gray-400">
            Dados da conta
          </span>
        </div>
      </div>
      <div class="c-row ui-mb-8">
        <div class="c-col">
          <info-card :items="userData" />
        </div>
      </div>

      <div class="c-row ui-mb-2">
        <div class="c-col">
          <span class="c-text-h3 c-text-gray-400">
            Dados cadastrais
          </span>
        </div>
      </div>
      <div class="c-row ui-mb-4">
        <div class="c-col">
          <info-card :items="userPersonalData" />
        </div>
      </div>

      <div class="c-row ui-mb-8">
        <div class="c-col">
          <info-card :items="userAddressData" />
        </div>
      </div>

      <div class="c-row ui-mb-2">
        <div class="c-col">
          <span class="c-text-h3 c-text-gray-400">
            Grupos de usuário
          </span>
        </div>
      </div>
      <div class="c-col ui-mb-8">
        <card class="c-row">
          <div class="c-row sm:c-col-9 md:c-col-10 lg:c-col-10">
            <div
              v-for="group of userGroups"
              :key="group"
            >
              <c-tag
                :data-cy="`group-${group}`"
                class="ui-mt-1 ui-mr-2"
              >
                {{ group }}
              </c-tag>
            </div>
            <div
              v-if="!userGroups.length"
              class="c-row c-col ui-justify-center"
            >
              <span class="c-text-b2 c-text-gray-300">
                Não há grupos a serem exibidos
              </span>
            </div>
          </div>
          <div class="sm:c-col-3 md:c-col-2 lg:c-col-2">
            <c-button
              outline
              size="sm"
              class="ui-float-right"
              color="gray"
              data-qa="gruposButton"
              title="Grupos"
              @click="$router.push({ name: 'users-groups', params: { id: userID } });"
            >
              <c-icon
                name="group"
                class="ui-mr-2"
                color="inherit"
              />
              Editar grupos
            </c-button>
          </div>
        </card>
      </div>

      <div class="c-row ui-mb-2">
        <div class="c-col">
          <span class="c-text-h3 c-text-gray-400">
            Redes Sociais
          </span>
        </div>
      </div>
      <div class="c-row ui-mb-8">
        <div class="c-col">
          <info-card :items="userSocialNetworks" />
        </div>
      </div>

      <div class="c-row ui-mb-2">
        <div class="c-col">
          <span class="c-text-h3 c-text-gray-400">
            Informações legadas
          </span>
        </div>
      </div>
      <div class="c-row ui-mb-8">
        <div class="c-col">
          <info-card :items="legacyData" />
        </div>
      </div>

      <div class="c-row ui-mb-2">
        <div class="c-col">
          <span class="c-text-h3 c-text-gray-400">
            Comentários
          </span>
        </div>
      </div>
      <card class="ui-mb-8">
        <div
          v-if="user.comment"
          class="ui-overflow-y-auto ui-whitespace-pre-line"
          style="max-height: 400px"
        >
          <span class="c-text-b1">
            {{ user.comment }}
          </span>
        </div>
        <div
          v-else
          class="c-row c-col ui-justify-center"
        >
          <span class="c-text-b2 c-text-gray-300">
            Não há comentários a serem exibidos
          </span>
        </div>
      </card>

      <!-- Sessões Ativas -->
      <template v-if="canListSessions">
        <div class="c-row ui-mb-4 ui-mt-8">
          <div class="c-col-full">
            <div class="c-d-flex">
              <span class="ui-flex-1 c-text-h3 c-text-gray-400">
                Sessões Ativas
              </span>
              <c-button
                outline
                color="gray"
                @click="() => openRelatorioSessoesAntigas()"
              >
                Ver todas sessões no Metabase
              </c-button>
            </div>
          </div>
        </div>
        <div v-if="sessions.length">
          <c-table
            :columns="sessionColumns"
            :data="sessions"
            row-variable-height
          >
            <template v-slot:column-created_at="{ item: session }">
              <span>
                {{ session.created_at | formatDateTime }}
              </span>
            </template>

            <template v-slot:column-actions="{ item: session }">
              <button
                @click="() => invalidateUserSession(session.session_id)"
                title="Desconectar"
              >
                <c-icon
                  name="logout-box"
                  color="red"
                />
              </button>
            </template>
          </c-table>
        </div>
        <card
          v-else
          class="ui-mb-8"
        >
          <div class="c-row c-col ui-justify-center">
            <span class="c-text-b2 c-text-gray-300">
              Não há itens a serem exibidos
            </span>
          </div>
        </card>
      </template>
    </div>
  </div>
</template>

<script>
import InfoCard from '@/components/info-card';
import { historyBackTo } from '@/utils/historyBackTo';
import { CButton, Icon, CTag, CIcon } from '@estrategiahq/coruja-web-ui';
import { requiredPermissionForLoginAsUser } from '../../constants';
import { format } from '@/utils/date';
import CTable from '../../components/c-table/CTable';
import PAvatar from '../../components/p-avatar';
import Card from '../../components/card';
import { env } from '../../plugins/env';
import dayjs from 'dayjs';
import { METABASE_URL } from '@/constants';

const formatDateTime = (date) => date ? format(date, 'DD/MM/YYYY - HH:mm') : '-';
const formatDate = (date) => date ? format(date, 'DD/MM/YYYY') : '-';

export default {
  components: {
    InfoCard,
    Icon,
    CButton,
    CTable,
    CTag,
    Card,
    PAvatar,
    CIcon,
  },
  data () {
    return {
      loadingUser: true,
      loadingSessions: false,
      loggedUser: null,
      userID: this.$route.params.id,
      isFormSending: false,
      user: {
        address: {},
      },
      sessions: [],
      sessionColumns: [
        { attribute: 'created_at', label: 'Data Login' },
        { attribute: 'ip_address', label: 'IP', maxWidth: '140px' },
        { attribute: 'user_agent', label: 'Agente de Usuário', flexWeight: 2 },
        { attribute: 'actions', label: 'Ações', maxWidth: '180px', justifyContent: 'center' },
      ],
    };
  },
  async mounted () {
    this.loggedUser = await this.$s.auth.getLoggedUser();
    await Promise.all([
      this.fetchUser(),
      this.fetchSessions(),
    ]);
  },
  computed: {
    canListSessions () {
      return this.loggedUser?.hasPermission('accounts.session.list');
    },
    isLoginAsOtherUserEnable () {
      return this.loggedUser?.hasPermission(requiredPermissionForLoginAsUser);
    },
    userData() {
      let userStatus = '-';
      let tagStatus = '';

      if (typeof this.user.is_active === 'boolean') {
        if (this.user.is_active) {
          userStatus = 'ATIVA';
          tagStatus = 'success';
        } else {
          userStatus = 'INATIVA';
          tagStatus = 'inactive';
        }
      }

      return [
        { label: 'Criado em', value: formatDateTime(this.user.created_at), dataCy: 'criadoEmField' },
        { label: 'ID do Usuário', value: this.userID, dataCy: 'idUsuarioField' },
        { label: 'ID Polvo', value: this.user.polvo_id, dataCy: 'idPolvoField' },
        { label: 'Status da Conta', tagValue: userStatus, tagStatus, dataCy: 'statusContaField' },
      ];
    },
    legacyData() {
      let isQuestionsFounderText = '-';
      if (typeof this.user.is_questions_founder === 'boolean') {
        isQuestionsFounderText = this.user.is_questions_founder ? 'Sim' : 'Não';
      }

      return [
        { label: 'Acesso em questões até', value: formatDate(this.user.questions_access_expiration), dataCy: 'acessoQuestoesAte' },
        { label: 'Acesso em cast até', value: formatDate(this.user.cast_access_expiration), dataCy: 'acessoCastAte' },
        { label: 'Fundador de questões', value: isQuestionsFounderText, dataCy: 'fundadorDeQuestoes' },
      ];
    },
    userPersonalData() {
      let tagValue = '-';
      let tagStatus = '';

      if (typeof this.user.require_email_confirmation === 'boolean') {
        if (this.user.require_email_confirmation) {
          tagValue = 'NÃO CONFIRMADO';
          tagStatus = 'orange';
        } else {
          tagValue = 'CONFIRMADO';
          tagStatus = 'success';
        }
      }

      return [
        { label: 'E-mail', value: this.user.email, dataCy: 'emailField', tagStatus, tagValue },
        { label: 'Telefone', value: this.user.mobile, mask: '(00) 00000-0000', dataCy: 'telefoneField' },
        { label: 'CPF', value: this.user.cpf, mask: '000.000.000-00', dataCy: 'cpfField' },
        { label: 'Data de nascimento', value: formatDate(this.user.birthday), dataCy: 'dataNascimentoField' },
      ];
    },
    userAddressData() {
      const state = this.user.address.state || '';
      const city = this.user.address.city || '';
      const cityAndState = city && state ? `${city} / ${state}` : '-';

      const number = this.user.address.number ? `, ${this.user.address.number}` : '';
      const streetAndNumber = this.user.address.number && number ? `${this.user.address.streetName}${number}` : '-';

      return [
        { label: 'Endereço', value: streetAndNumber, dataCy: 'enderecoField' },
        { label: 'Complemento', value: this.user.address.complement, dataCy: 'complementoField' },
        { label: 'CEP', value: this.user.address.zipCode, mask: '00000-000', dataCy: 'cepField' },
        { label: 'Cidade / Estado', value: cityAndState, dataCy: 'cidadeEstadoField' },
      ];
    },
    userGroups() {
      if (!this.user.groups) return [];
      return this.user.groups.map(g => g.name);
    },
    userSocialNetworks() {
      if (!this.user.social_networks) return [];
      return this.user.social_networks.map(sn => ({ label: sn.name, value: sn.profile_social_network_url_path, dataCy: `social-network-${sn.profile_social_network_url_path}` }));
    },
  },
  filters: {
    formatDateTime,
  },
  methods: {
    sessionStatusBackGroung: (expiredAt) => expiredAt ? 'var(--c-color-gray)': 'var(--c-color-green)',
    async loginAsOtherUser () {
      const ok = await this.$dialog.show({
        title: 'Entrar na conta do usuário',
        message: 'Atenção!<br> Essa ação será registrada.<br>Se prosseguir, não se esqueça de ao final deslogar do Perfil do usuário.',
      });
      if (!ok) return;
      await this.$s.user.loginAsOtherUser(this.user.id);
      window.open(env.PERFIL_URL, '_blank');
    },
    backToUsersList() {
      historyBackTo({
        key: 'users-list-search-url',
        router: this.$router,
        fallback: { name: 'users' },
      });
    },
    async invalidateUserSession(sessionID) {
      const accepted = await this.$dialog.show({
        title: 'Desconectar sessão',
        message: 'Realmente deseja desconectar esta sessão?',
      });
      if (!accepted) return;

      await this.$s.session.invalidateUserSession(this.userID, sessionID);
      this.$toast.show({
        type: 'success',
        text: 'Sessão desconectada com sucesso!',
        timeout: 0,
      });

      this.sessions = this.sessions.filter(s => {
        return s.session_id !== sessionID;
      });
    },
    async fetchUser() {
      this.user = {};
      this.loadingUser = true;
      const user = await this.$s.user.getById(this.userID);
      this.user = user;
      this.loadingUser = false;
    },
    async fetchSessions() {
      this.sessions = [];
      if (!this.canListSessions) {
        return;
      }
      this.loadingSessions = true;
      const sessions = await this.$s.session.getUserSessions(this.userID);
      this.sessions = sessions;
      this.loadingSessions = false;
    },
    openRelatorioSessoesAntigas() {
      const relatorioPath = 'question/10987-relatorio-de-sessoes';
      const dateFrom = dayjs().subtract(7, 'days').format('YYYY-MM-DD');
      const dateTo = dayjs().format('YYYY-MM-DD');
      window.open(`${METABASE_URL}/${relatorioPath}?Data_Login=${dateFrom}~${dateTo}&Email=${this.user.email}`, '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
@include c-mq(sm) {
  #avatar-container {
    height: 80px;
    width: 80px;
  }
}

.back-button {
  padding: 0;
  height: 2rem;
  width: 2rem;
}

.border-gray {
  border-color: var(--c-color-gray-200)
}
</style>
